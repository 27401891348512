<template>
  <div @mousemove="mixin_SetLastActivityTime">
    <router-view />
  </div>
</template>

<script>
import ClientService from "@/services/client.service";
export default {
  mounted() {
    let rootObject = this;
    if (this.$store.state.auth.status.loggedIn) {
      ClientService.verifyJwtToken().then(
        (response) => {
          console.log(response.status);
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    } else {
      rootObject.mixin_setLoginData(false);
    }
  },
};
</script>
