import axios from "axios";
import NProgress from "nprogress";
import store from "@/store"; 
import router from "@/router";

const API_URL = process.env.VUE_APP_API_BASE_POINT;

// create a new axios instance
const axiosInstance = axios.create({
  baseURL: API_URL,
});

// before a request is made start the nprogress
axiosInstance.interceptors.request.use((config) => {
  NProgress.start();
  return config;
});

// before a response is returned stop nprogress
axiosInstance.interceptors.response.use((response) => {
  NProgress.done();
  if(response.data.message==="Unauthorized."){
    handleUnauthorized();
  }
  return response;
},
(error) => {
  NProgress.done();
  if (error.response && error.response.message === "Unauthorized.") {
    handleUnauthorized();
  }
  return Promise.reject(error);
});

function handleUnauthorized() {
  let intervalVisitingActivityObj = localStorage.getItem("visitActvityProcess");
  if(intervalVisitingActivityObj){
    clearInterval(intervalVisitingActivityObj);
  }

  let intervalNotificationActivityObj = localStorage.getItem("notificationActvityProcess");
  if(intervalNotificationActivityObj){
    clearInterval(intervalNotificationActivityObj);
  }
  store.dispatch("keepSignedIn/keepSign", false);
  store.dispatch("auth/logout");
  router.push({ path: "/signin" });
}
export default axiosInstance;
